@mixin bg-color($color) {
  background-color: $color !important;
}

.bg {
    &-gray {
        @include bg-color(var(--gray));
    }

    &-light-gray {
        @include bg-color(var(--light-gray));
    }

    &-soft-gray {
        @include bg-color(var(--soft-gray));
    }

    &-gray-026 {
        @include bg-color(var(--gray-026));
    }

    &-disabled-gray {
        @include bg-color(var(--disabled-gray));
    }

    &-white {
        @include bg-color(var(--white));
    }

    &-black {
        @include bg-color(var(--black));
    }

    &-yellow-bright  {
        @include bg-color(var(--yellow-bright ));
    }

    &-light-black {
        @include bg-color(var(--light-black));
    }

    &-black-092 {
        @include bg-color(var(--black-092));
    }

    &-yellow {
        @include bg-color(var(--yellow));
    }

    &-orange {
        @include bg-color(var(--orange));
    }

    &-golden {
        @include bg-color(var(--golden));
    }

    &-soft-orange {
        @include bg-color(var(--soft-orange));
    }

    &-soft-rose {
        @include bg-color(var(--soft-rose));
    }

    &-rose {
        @include bg-color(var(--rose));
    }

    &-rose-video {
        @include bg-color(var(--rose-video));
    }

    &-light-rose {
        @include bg-color(var(--rose));
    }

    &-strong-rose {
        @include bg-color(var(--strong-rose));
    }

    &-magenta {
        @include bg-color(var(--magenta));
    }

    &-magenta-light {
        @include bg-color(var(--magenta-light));
    }

    &-soft-green {
        @include bg-color(var(--soft-green));
    }

    &-cyan {
        @include bg-color(var(--cyan));
    }

    &-black-008 {
        @include bg-color(var(--black-008));
    }

    &-black-005 {
        @include bg-color(var(--black-005));
    }

    &-red {
        @include bg-color(var(--red));
    }

    &-red-005 {
        @include bg-color(var(--red-005));
    }

    &-ice-blue {
        @include bg-color(var(--ice-blue));
    }

    &-light-orange {
        @include bg-color(var(--light-orange));
    }

    &-light-orange2 {
        @include bg-color(var(--light-orange2));
    }

    &-soft-blue {
        @include bg-color(var(--soft-blue));
    }

    &-blue {
        @include bg-color(var(--blue));
    }

    &-cyan {
        @include bg-color(var(--cyan));
    }

    &-blue-005 {
        @include bg-color(var(--blue-005));
    }

    &-sky-blue-03 {
        @include bg-color(var(--sky-blue-03));
    }

    &-sky-blue {
        @include bg-color(var(--sky-blue));
    }

    &-light-blue {
        @include bg-color(var(--light-blue));
    }

    &-light-blue-03 {
        @include bg-color(var(--light-blue-03));
    }

    &-sea-blue {
        @include bg-color(var(--sea-blue));
    }

    &-sea-blue-096 {
        @include bg-color(var(--sea-blue-096));
    }

    &-white-gray {
        @include bg-color(var(--white-gray));
    }

    &-white-04 {
        @include bg-color(var(--white-04));
    }
}

