.home-degrade {
    background-repeat: no-repeat;
    background-position: right top;
    height: 800px;
    background-image: url("../../images/web/home-page/new-intro-desktop.png");
    @include breakpoint(desktop) {
        background-position: right 65%;
        height: 800px;
        background-image: url("../../images/web/home-page/new-intro-tablet.png");
    }
    @include breakpoint(tablet) {
        background-position: right 65%;
        height: 800px;
        background-image: url("../../images/web/home-page/new-intro-tablet.png");
    }


    @include breakpoint(mobile) {
        background-position: right 97%;
        height: 450px;
        background-image: url("../../images/web/home-page/new-intro-mobile.png");
    }
}

.about-us-degrade {
    background-repeat: no-repeat;
    background-position: right top;
    height: 800px;
    background-image: url("../../images/web/about-us/intro-desktop.svg");
    @include breakpoint(desktop) {
        background-position: right 80%;
        height: 800px;
        background-image: url("../../images/web/about-us/intro-tablet.svg");
    }

    @include breakpoint(tablet) {
        background-position: right 90%;
        height: 800px;
        background-image: url("../../images/web/about-us/intro-tablet.svg");
    }

    @include breakpoint(mobile) {
        background-position: right 95%;
        height: 500px;
        background-image: url("../../images/web/about-us/intro-mobile.svg");
    }
}

.how-works-degrade {
    background-repeat: no-repeat;
    background-position: right top;
    background-image: url("../../images/web/how-works/how-works-intro.svg");
    @include breakpoint(desktop) {
        background-position: right 35%;
        background-size: 271px 339px;
    }
    @include breakpoint(tablet) {
        background-position: right 35%;
        background-size: 271px 339px;
    }

    @include breakpoint(mobile) {
        background-position: right 45%;
        background-size: 171px 214px;
    }
}

.freelancers-degrade {
    background-repeat: no-repeat;
    background-position: right top;
    height: 800px;
    background-image: url("../../images/web/freelancers/freelancer-intro.svg");
    background-size: 350px;
    @include breakpoint(desktop) {
        background-position: right 60%;
        height: 800px;
        background-size: 300px;
    }
    @include breakpoint(tablet) {
        background-position: right 65%;
        height: 800px;
        background-size: 250px;
    }

    @include breakpoint(mobile) {
        background-position: right bottom;
        background-size: 200px;
        height: 485px;
    }
}

.clients-degrade {
    background-repeat: no-repeat;
    background-position: 100% 20%;
    background-image: url("../../images/web/clients/client-intro.svg");
    background-size: 350px;
    .intro-contain{
        height: 800px;
    }
    @include breakpoint(desktop) {
        background-position: 90% 65%;
        background-size: 250px;
        .intro-contain{
            height: 800px;
        }
    }
    @include breakpoint(tablet) {
        background-position: 90% 65%;
        .intro-contain{
            height: 800px;
        }
    }

    @include breakpoint(mobile) {
        background-position: 90% 90%;
        background-size: 150px;
        .intro-contain{
            height: 450px;
        }
    }
}

.alpha-mobile {
    background-color: transparent;
    @include breakpoint(tablet) {
        background-color: #ffffffbe;
    }
    @include breakpoint(mobile) {
        background-color: #ffffffbe;
    }
}