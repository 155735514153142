.rotated-text {
  position: fixed !important;
  bottom: 80px;
  left: 50px;
  transform: rotate(-90deg);
  transform-origin: top left;
}

@media screen and (max-width: 1024px) {
  .rotated-text {
    position: absolute !important;
    bottom: 1300px;
    right: -804px;
  }
}

@media screen and (max-width: 415px) {
  .rotated-text {
    display: none;
  }
}
