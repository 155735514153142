.web-payment {
   background-image: url("../../images/web/payment-bg-desktop.svg");
   background-repeat: no-repeat;
   background-size:cover;

    @include breakpoint(tablet) {
       background-image: url("../../images/web/payment-bg-desktop.svg");
    }

    @include breakpoint(mobile) {
       background-image: url("../../images/web/payment-bg-mobile.svg");

    }
    @include degrade-structure
}