.custom-select {
  display: inline-block;
  vertical-align: middle;
  background: url('data:image/svg+xml,<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="%23bdbdbd" xmlns="http://www.w3.org/2000/svg"><path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg>')
    no-repeat right 0.5rem center/15px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 48px;
  font-family: 'WorkSans-Regular';
  font-size: 14px;
  line-height: 22px;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 430px) {
    font-size: 15px;
    line-height: 21px;
  }
}


