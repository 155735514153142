@mixin text-color($color) {
  color: $color !important;
}

.text {
  &-black {
    @include text-color(var(--black));
  }
  &-light-black {
    @include text-color(var(--light-black));
  }
  &-white {
    @include text-color(var(--white));
  }
  &-red {
    @include text-color(var(--red));
  }
  &-magenta {
    @include text-color(var(--magenta));
  }
  &-magenta-light {
    @include text-color(var(--magenta-light));
  }
  &-soft-green {
    @include text-color(var(--soft-green));
  }
   &-soft-soft-green {
    @include text-color(var(--soft-soft-green));
  }
  &-sky-blue {
    @include text-color(var(--sky-blue));
  }
  &-sea-blue-096 {
    @include text-color(var(--sea-blue-096));
  }
  &-sea-blue {
    @include text-color(var(--sea-blue));
  }
   &-sea-blue2 {
    @include text-color(var(--sea-blue2));
  }
  &-blue {
    @include text-color(var(--blue));
  }
  &-cyan {
    @include text-color(var(--cyan));
  }
  &-rose {
    @include text-color(var(--rose));
  }
  &-rose-video {
    @include text-color(var(--rose-video));
  }
  &-strong-rose {
    @include text-color(var(--strong-rose ));
  }
  &-light-rose {
    @include text-color(var(--light-rose));
  }
  &-soft-rose {
    @include text-color(var(--soft-rose));
  }
  &-soft-blue {
    @include text-color(var(--soft-blue));
  }
  &-orange {
    @include text-color(var(--orange));
  }
  &-orange2 {
    @include text-color(var(--orange2));
  }
  &-yellow {
    @include text-color(var(--yellow));
  }
  &-light-gray {
    @include text-color(var(--light-gray));
  }
  &-soft-gray {
    @include text-color(var(--soft-gray));
  }
  &-gray {
    @include text-color(var(--gray));
  }
  &-disabled-gray {
    @include text-color(var(--disabled-gray));
  }
  &-golden {
    @include text-color(var(--golden));
  }
}

