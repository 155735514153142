.no-focus {
    &:focus {
        outline: none !important;
    }
}

.new-project-steps {
  height: 6px;
  border-radius: 8px;
  background: linear-gradient(270deg, #FFBB1E 6.83%, #FB4E00 24.55%, #FF1C6D 36.92%, #FF56D3 52.58%, #64A2FE 67.41%, #4FE34C 83.9%);
}

.MuiLinearProgress-colorPrimary {
  height: 6px;
  border-radius: 8px;
  background: linear-gradient(270deg, #FFBB1E 6.83%, #FB4E00 24.55%, #FF1C6D 36.92%, #FF56D3 52.58%, #64A2FE 67.41%, #4FE34C 83.9%);
}

.outline-none {
  outline: none !important;
}

.overflow-hidden-x {
  overflow-x: hidden !important;
}

.bg-blur {
  filter: blur(5px);
}

.h-small-screen {
  height: 100svh !important;
}

.gray-scale-filter {
  filter: grayscale(100%);
}

.no-height {
    height: unset !important;
}

.total-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.transform-270deg {
    transform: rotate(270deg);
}

.footer-isotype {
  width: 162px;
  height: 155px;
  @include breakpoint(tablet) {
    width: 104px;
    height: 100px;
  }
  @include breakpoint(mobile) {
    width: 90px;
    height: 87px;
  }
}

.featured-isotype {
  width: 82px;
  height: 78px;
  @include breakpoint(tablet) {
    width: 82px;
    height: 78px;
  }
  @include breakpoint(mobile) {
    width: 82px;
    height: 78px;
  }
}

.rotated-left {
  transform: rotate(-90deg);
}

.rotated-right {
  transform: rotate(90deg);
}

.slider {
    display: flex;
    width: 100% !important;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    overflow-y: hidden;
}

.slider-item {
    flex: none;
    scroll-snap-align: center;
}

.hide-scroll-bar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.h-600-to-unset {
  height: 600px;
  @include breakpoint(mobile) {
    height: unset;
  }
}

.findie-border-radius {
  border-radius: 42% 40% 45% 44% / 45% 44% 40% 42%;
}

.porfolio-avatar {
  width: 203px;
  height: 195px;
  border-radius: 42% 40% 45% 44% / 45% 44% 40% 42%;
  overflow: hidden;
  @include breakpoint(mobile) {
    width: 129px;
    height: 123px;
  }
}

.square-porfolio-avatar {
  width: 450px;
  height: 450px;
  @include breakpoint(desktop-xl) {
    width: 343px;
    height: 343px;
  }
  @include breakpoint(tablet) {
    width: 209px;
    height: 209px;
  }
  @include breakpoint(mobile) {
    width: 152px;
    height: 152px;
  }
}

.portfolio-avatar-freelancers-profiles {
  width: 95px;
  height: 91px;
  border-radius: 42% 40% 45% 44% / 45% 44% 40% 42%;
  overflow: hidden;
}

.small-avatar {
  width: 33px !important;
  height: 32px !important;
  margin-top: -15px;
  margin-left: 5px;
}

.home-freelancer-categories {
  margin: 0 17%;
  @include breakpoint(tablet) {
    margin: 0
  }
}

.min-screen-height {
  min-height: 100svh;
}

.slick-track {
  margin-bottom: 10px !important
}

.black-white-filter {
  & img {
    transition: all 200ms;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  &:hover {
    transition: all 200ms;
    & img {
      -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
      filter: grayscale(0%);
    }
  }
}
    
.custom-button-bg {
  color: var(--black);
  overflow: hidden;

}

.custom-button-bg:hover {
  overflow: hidden;
  transition: animation 0.6s ease-in;
  animation: spin 1s ease-in infinite;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transition: all 0.6s ease-in;
    color: white;
    background-image: conic-gradient(
      #FF1C6D,
      #FF56D3,
      #64A2FE,
      #4FE34C,
      #FFBB1E
      );
    }
    25% {
      transition: all 0.6s ease-in;
    color: white;
    background-image: conic-gradient(
      #FF56D3,
      #64A2FE,
      #4FE34C,
      #FFBB1E,
      #FF1C6D
      );
    }
    50% {
      transition: all 0.6s ease-in;
      color: white;
      background-image: conic-gradient(
        #64A2FE,
        #4FE34C,
        #FFBB1E,
        #FF1C6D,
        #FF56D3
        );
      }
      75% {
        transition: all 0.6s ease-in;
        color: white;
        background-image: conic-gradient(
          #4FE34C,
          #FFBB1E,
      #FF1C6D,
      #FF56D3,
      #64A2FE
      );
    }
  100% {
    transition: all 0.6s ease-in;
    color: white;
    background-image: conic-gradient(
      #FFBB1E,
      #FF1C6D,
      #FF56D3,
      #64A2FE,
      #4FE34C
  );
  }
}


.button-container {
	border-radius: 44px;
  width: fit-content;
  overflow: hidden;
}

.custom-button {
	--border-radius: 44px;
	--border-width: 1.5px;
  overflow: hidden;
	appearance: none;
	position: relative;
	border: 0;
	background-color: transparent;
}

.custom-button::after {
	--m-i: linear-gradient(#000, #000);
	--m-o: content-box, padding-box;

	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: var(--border-width);
	border-radius: var(--border-radius);
	background-image: conic-gradient(
    #FFBB1E,
    #FF1C6D,
    #FF56D3,
    #64A2FE,
    #4FE34C
	);
  overflow: hidden;
	mask-image: var(--m-i), var(--m-i);
	mask-origin: var(--m-o);
	mask-clip: var(--m-o);
	mask-composite: exclude;
	-webkit-mask-composite: destination-out;

	filter: hue-rotate(0);
	// animation: hue linear 500ms infinite;
	// animation-play-state: paused;
}

.custom-button:hover::after {
	display: none
}

.custom-button-no-hover {
	--border-radius: 44px;
	--border-width: 1.5px;
  overflow: hidden;
	appearance: none;
	position: relative;
	border: 0;
	background-color: transparent;
}

.custom-button-no-hover::after {
	--m-i: linear-gradient(#000, #000);
	--m-o: content-box, padding-box;

	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: var(--border-width);
	border-radius: var(--border-radius);
	background-image: conic-gradient(
    #FFBB1E,
    #FF1C6D,
    #FF56D3,
    #64A2FE,
    #4FE34C
	);
  overflow: hidden;
	mask-image: var(--m-i), var(--m-i);
	mask-origin: var(--m-o);
	mask-clip: var(--m-o);
	mask-composite: exclude;
	-webkit-mask-composite: destination-out;

	filter: hue-rotate(0);
}

@keyframes hue {
	to {
		filter: hue-rotate(1turn);
	}
}

.gradient-box {
  border: 2px solid transparent
}

.gradient-color {
	border-image: linear-gradient(to right, 
    #FFBB1E,
    #FF1C6D,
    #FF56D3,
    #64A2FE,
    #4FE34C
	) 1;
  border-radius: 10px
}

.border-rounded {
  border-radius: 9999px !important;
}

.freelancer-gallery-grid {
    display: grid;
    grid-template-columns: 17% 1fr;
}

@media screen and (max-width: 1024px) {
    .freelancer-gallery-grid {
        grid-template-columns: 0 1fr 0;
    }
}

@media screen and (max-width: 430px) {
    .freelancer-gallery-grid {
        grid-template-columns: 0 1fr 0;
    }
}

//Email verification component
.vi__wrapper {
  width: 100%;
  position: relative;
}

.vi__container {
  display: flex;
  gap: 8px;
  height: 50px;
  width: 100%;
}

.vi__character {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  font-size: 36px;
  line-height: 50px;
  color: black;
  background-color: white;
  border: 1px solid black;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
}

//Modal
.MuiPaper-rounded {
  border-radius: 0px !important;
}

.brief-drawer {
  width: 50vw !important;
  @media screen and (max-width: 900px) {
    width: 50vw !important;
  }
  @media screen and (max-width: 430px) {
    width: 100vw !important;
  }

}

.table-candidate-card-grid {
  display: grid;
  grid-template-columns: 70px 1fr;
}