.ul-container {
  overflow: hidden;
  height: 80px;
  padding: 0;
  @include breakpoint(tablet) {
    height: 90px;
  }
    @include breakpoint(mobile) {
    height: 50px;
  }
}

.slide-animation {
    list-style: none;
    position: relative;
    top: 0;
    left: 0;
    animation: slide 6.5s steps(3) forwards infinite;
}

.type-animation{
  position: relative;
  width: max-content;
  overflow: hidden;
}

.type-animation::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--white);
  animation: type 1.3s linear infinite;
}
    
@keyframes type {
  to {
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}

@keyframes slide {
  to {
    top: -240px
  }
}

@include breakpoint(tablet) {
  @keyframes slide {
    to {
      top: -270px
    }
  }
}

@include breakpoint(mobile) {
  @keyframes slide {
    to {
      top: -150px
    }
  }
}

