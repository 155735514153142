:root {
  //Breakpoints
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  
  //Palette
  --black: #000000;
  --light-black: #171515; 
  --black-092: rgba(23, 21, 21, 0.92); 
  --black-02: rgba(0, 0, 0, 0.2);
  --black-008: rgba(0, 0, 0, 0.08);
  --black-005: rgba(0, 0, 0, 0.05);
  
  --white: #ffffff;
  --white-04: rgba(255, 255, 255, 0.9);
  --white-gray: #e0e0e0;

  --dark-orange: #f89500;
  --orange: #fca404;
  --soft-orange: #ffebd8;
  --light-orange: #ffe0c1;
  --light-orange2: #FFF7EF;

  --yellow: #ffbb1f;
  --yellow-bright : #FFE500;
  --golden: #D9BC81;

  --strong-rose: #FF9DCE;
  --rose: #ffe7e8;
  --rose-video: #FF56D3;
  --soft-rose: #FFBAEC;
  --light-rose: #FEF5F5;

  --magenta: #fc348c;
  --magenta-light: #FFDEEA;

  --red: #fb4e00;
  --red-005: rgba(251, 78, 0, 0.05);

  --ice-blue: #F4F9FF;
  --light-blue: #85b6ff;
  --light-blue-03: rgba(88, 163, 216, 0.3);
  --blue: #0063FE;
  --blue-005: rgba(100, 162, 254, 0.05);
  --sea-blue: #006491;
  --sea-blue2: #016A97;
  --sea-blue-096: rgba(0, 99, 141, 0.96);
  --soft-blue: #58A3D8;
  --sky-blue: #73acd4;
  --sky-blue-03: rgba(115, 172, 212, 0.3);

  --soft-green: #2ec68f;

  --cyan: #33C0F1;

  --light-gray: #E1E1E1;
  --disabled-gray: #E0E0E0;
  --gray: #444040;
  --soft-gray: #bdbdbd;
  --gray-05: rgba(0, 0, 0, 0.5);
  --gray-026: rgba(206, 204, 204, 0.26);

  --tw-ring-color: black;
}

@mixin shadow($color) {
  box-shadow: $color !important;
}

.shadow {
  &-black-005 {
    @include shadow(12px 13.8974px 34.7436px var(--black-005));
  }
  &-black-025 {
    -webkit-box-shadow: -2px -1px 14px -2px rgba(0,0,0,0.45);
    -moz-box-shadow: -2px -1px 14px -2px rgba(0,0,0,0.45);
    box-shadow: -2px -1px 14px -2px rgba(0,0,0,0.45);
  }
}
