@mixin breakpoint($point) {
    @if $point == desktop-2xl {
        @media (max-width: 1900px) {
            @content;
        }
    }
    @if $point == desktop-xl {
        @media (max-width: 1560px) {
            @content;
        }
    }
    @if $point == desktop {
        @media (max-width: 1280px) {
            @content;
        }
    }
    @else if $point == tablet {
        @media (max-width: 800px) {
            @content;
        }
    }
    @else if $point == mobile {
        @media (max-width: 430px) {
            @content;
        }
    }
}
