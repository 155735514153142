@font-face {
    font-family: 'SharpGroteskMedium22';
    src: local("SharpGroteskMedium22"), url("../../fonts/SharpGroteskMedium22.woff") format("truetype");
}

@font-face {
    font-family: 'SharpGroteskMedium20';
    src: local("SharpGroteskMedium20"), url("../../fonts/SharpGroteskMedium20.otf") format("truetype");
}

@font-face {
    font-family: 'SharpGroteskBook20';
    src: local("SharpGroteskBook20"), url("../../fonts/SharpGroteskBook20.woff") format("truetype");
}

@font-face {
    font-family: 'WorkSans-Bold';
    src: local("WorkSans-Bold"), url("../../fonts/WorkSans-Bold.woff") format("truetype");
}

@font-face {
    font-family: 'WorkSans-SemiBold';
    src: local("WorkSans-SemiBold"), url("../../fonts/WorkSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'WorkSans-Medium';
    src: local("WorkSans-Medium"), url("../../fonts/WorkSans-Medium.woff") format("truetype");
}

@font-face {
    font-family: 'WorkSans-Regular';
    src: local("WorkSans-Regular"), url("../../fonts/WorkSans-Regular.woff") format("truetype");
}

@font-face {
    font-family: 'WorkSans-Light';
    src: local("WorkSans-Light"), url("../../fonts/WorkSans-Light.woff") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap');

.Typewriter__cursor {
  font-family: 'Source Code Pro', monospace;
  margin-left: -15px !important;
  margin-bottom: 5px;
}
