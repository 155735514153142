.border-sky-blue {
  border-color: var(--sky-blue) !important;
}

.border-orange {
  border: 1px solid var(--orange) !important;
}

.border-strong-rose {
  border: 1px solid var(--strong-rose) !important;
}

.border-magenta {
  border: 1px solid var(--magenta) !important;
}

.border-b-magenta {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--magenta) !important; 
}

.border-b-orange {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--orange) !important; 
}

.border-b-blue {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--blue) !important; 
}

.border-blue {
  border: 1px solid var(--blue) !important; 
}

.border-red {
  border: 1px solid var(--red) !important; 
}

.border-cyan {
  border: 1px solid var(--cyan) !important; 
}

.border-soft-green {
border: 1px solid var(--soft-green) !important; 
}

.border-golden {
border: 1px solid var(--golden) !important; 
}

.border-light-gray {
  border: 1px solid var(--light-gray) !important; 
}

.border-r-blue {
  border-top: none;
  border-left: none;
  border-right: 1px solid var(--blue) !important;
  border-bottom: none; 
}

.border-b-blue {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--blue) !important; 
}

.outline-black {
   outline: 1px solid #000000 !important;
}

.outline-white {
    outline: 1px solid #ffffff !important;
}
