.in-animation {
  animation: inAnimation 550ms;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}



.switch {
  display: inline-flex;
  align-items: center;
}
.switch_box {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
input[type="checkbox"].switch {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 250px;
  height: 70px;
  background: transparent;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: 1px solid white;
}
.off {
  display: none;
}
.on {
  position: relative;
  left: -50px;
  font-weight: bolder;
}
input[type="checkbox"].switch:checked {
  background: transparent;
  border: 1px solid white;
}
input[type="checkbox"].switch:checked ~ .on {
  display: none;
}
input[type="checkbox"].switch:checked ~ .off {
  display: inline;
  position: relative;
  left: -88px;
}
input[type="checkbox"].switch:after {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--blue);
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch:checked:after {
  left: calc(100% - 70px);
  background: var(--orange);
}
