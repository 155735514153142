.animation-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.moving-text {
  display: inline-block;
  white-space: nowrap; /* Evita que las palabras se separen */
  margin: 0;
  animation: moveLeft 100s linear infinite; /* Ajusta la duración y velocidad aquí */
}

@keyframes moveLeft {
  from {
    transform: translateX(15%); /* Comienza fuera del contenedor a la derecha */
  }
  to {
    transform: translateX(-100%); /* Llega al otro lado del contenedor a la izquierda */
  }
}