.user-profile {
  &__container {
    display: grid;
    grid-template-columns: 135px 1fr;
    height: 100svh;
    @media (max-width: 825px) {
      grid-template-rows: 125px 1fr 100px;
      grid-template-columns: 1fr;
    }
    @media (max-width: 430px)  {
      grid-template-rows: 75px 1fr 85px;
      grid-template-columns: 1fr;
    }
  }

  &__header { 
    display: grid;
    grid-template-rows: 170px 1fr;
    height: 100svh;
  }

  &__main { 
    display: grid;
    grid-template-columns: 1fr 135px;
    height: calc(100svh - 170px);
    @media (max-width: 1030px) {
      grid-template-columns: 1fr;
    }
  }

  &__overflow {
    overflow-y: auto;
    overflow-x: hidden;
  }
}


