.wizard-grid {
  display: grid;
  grid-template-columns: 1fr 260px;
  grid-template-rows: 1fr;
  height: 100svh;
  width: 100%;
  overflow-x: hidden;

  @include breakpoint(tablet) {
    grid-template-columns: 1fr 160px;
  }
  
  @include breakpoint(mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: 88px 1fr;
  }
}

.form-content-height {
  height: 100svh;
  @include breakpoint(mobile) {
    height: calc(100svh - 90px);
  }
}